export class Lot {
    CreateDate: string;
    Documents: string;
    Id: number;
    Number: string;
    Place: string;
    Price: number;
    PartsPrice: number;
    Title: string;
    Year: number;
    Region: string;
    CategoryType: string;
    ObjectType: string;
    Vendor: string;
    Model: string;
    Square: string;
    Mileage: string;
    LastChangedBy: string;
    LastChanges: string;
    Photos: any[];
  }