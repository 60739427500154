import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManagerService } from '../../../../manager.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  userName: string = '';
  password: string = '';
  passwordConfirm: string = '';
  regions;

  constructor(private route: ActivatedRoute,private router: Router, private managerService: ManagerService) { }

  ngOnInit() {
  }
  onSubmit() {
    this.managerService.createUser(this.userName, this.password, this.regions)
    .subscribe(data => {
      console.log(data)
      this.router.navigate(['manager/users']);
    } , error => console.log(error));  
  }
  cancel() {
    this.router.navigate(['manager/users']);
  }


}
