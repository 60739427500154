import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { NgxGalleryModule } from 'ngx-gallery';

import { FilterComponent } from './filter/filter.component';
import 'hammerjs';
import { HomeComponent } from './home/home.component';
import { LotsComponent } from './lots/lots.component';
import { BiddingComponent } from './bidding/bidding.component';
import { ManagerComponent, NgbdModalContent } from './manager/manager.component';

import { Routes, RouterModule } from '@angular/router';
import { JwtModule } from "@auth0/angular-jwt";

import { ManagerService } from './manager.service';
import { AuthGuardService } from './auth-guard.service';
import { UpdateLotComponent } from './update-lot/update-lot.component';
import { CreateLotComponent } from './create-lot/create-lot.component';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import { LoginComponent } from './login/login.component';
import { PlacesComponent } from './places/places.component';
import { PartsComponent } from './parts/parts.component';
import { ContactsComponent } from './contacts/contacts.component';
import { BiddingManagerComponent } from './bidding-manager/bidding-manager.component';
import { LotDetailsModalComponent } from './lot-details-modal/lot-details-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsermanagementComponent } from './manager/support/users/usermanagement.component';
import { RegionmanagementComponent } from './manager/support/regions/regionmanagement.component';
import { UserEditComponent } from './manager/support/users/user-edit/user-edit.component';
import { UserCreateComponent } from './manager/support/users/user-create/user-create.component';
import { LogModalComponent } from './manager/log-modal/log-modal.component';
import { ErrorModalComponent } from './manager/error-modal/error-modal/error-modal.component';
import { UniversalModalComponent } from './universal-modal/universal-modal.component';

export function tokenGetter() {
  return localStorage.getItem("jwt");
}


const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'lots', component: LotsComponent},
  {path: 'parts', component: PartsComponent },
  {path: 'places', component: PlacesComponent },
  {path: 'contacts', component: ContactsComponent },
  {path: 'manager', component: ManagerComponent, canActivate: [AuthGuardService] },
  {path: 'events', component: BiddingManagerComponent, canActivate: [AuthGuardService] },
  {path: 'bidding', component: BiddingComponent},
  {path: 'update/:id', component: UpdateLotComponent },
  {path: 'add', component: CreateLotComponent },
  {path: 'login', component: LoginComponent },
  {path: 'manager/users', component: UsermanagementComponent, canActivate: [AuthGuardService] },
  {path: 'manager/users/edit/:userName', component: UserEditComponent, canActivate: [AuthGuardService] },
  {path: 'manager/users/create', component: UserCreateComponent, canActivate: [AuthGuardService] }
]

@NgModule({
  declarations: [
    AppComponent,
    FilterComponent,
    HomeComponent,
    LotsComponent,
    BiddingComponent,
    ManagerComponent,
    UpdateLotComponent,
    CreateLotComponent,
    ModalBasicComponent,
    NgbdModalContent,
    LoginComponent,
    PlacesComponent,
    PartsComponent,
    ContactsComponent,
    BiddingManagerComponent,
    LotDetailsModalComponent,
    UsermanagementComponent,
    RegionmanagementComponent,
    UserEditComponent,
    UserCreateComponent,
    LogModalComponent,
    ErrorModalComponent,
    UniversalModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot(appRoutes),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        blacklistedRoutes: ["example.com/examplebadroute/"]
      }
    }),
    //AppRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxBootstrapSliderModule,
    NgxGalleryModule,
    FormsModule, ReactiveFormsModule
  ],
  providers: [ManagerService, ModalBasicComponent, NgbActiveModal, NgbModal],
  bootstrap: [AppComponent],
  entryComponents: [NgbdModalContent, LotDetailsModalComponent,LogModalComponent,ErrorModalComponent,UniversalModalComponent]
})
export class AppModule { 
}
