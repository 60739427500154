import { Component, OnInit } from '@angular/core';
import ymaps from 'ymaps';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss']
})
export class PlacesComponent implements OnInit {


  constructor() { }

  ngOnInit() {
    document.getElementById("nav-footer").style.visibility = 'visible';
    ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru-RU')
      .then(maps => {
        console.log(maps);
        var myMap = new maps.Map('map', {
          center: [51.660781, 39.200269],
          zoom: 6
        });
        var myPlacemark = new maps.Placemark([50.189693, 38.098881], {
          balloonContentHeader: "Смотровая площадка",
          balloonContentBody: "Белгородская область, г.Валуйки, ул.Никольская 134А",
          // balloonContentFooter: "(возле мастерской)",
          hintContent: "Смотровая площадка"
        });
        var myPlacemark2 = new maps.Placemark([50.880699, 37.784605], {
          balloonContentHeader: "Смотровая площадка",
          balloonContentBody: "Белгородская область, Чернянский район, с.Ездочное",
          // balloonContentFooter: "(возле тов. тока с.Ездочное)",
          hintContent: "Смотровая площадка"
        });
        myMap.geoObjects.add(myPlacemark);
        myMap.geoObjects.add(myPlacemark2);
        myMap.geoObjects.add(new maps.Placemark([51.186510, 37.380687], {
          balloonContentHeader: "Смотровая площадка",
          balloonContentBody: "Белгородская область, Губкинский район, с.Сергеевка",
          // balloonContentFooter: "(возле сем тока в с.Сергеевка)",
          hintContent: "Смотровая площадка"
        }));
        myMap.geoObjects.add(new maps.Placemark([53.159859, 36.841590], {
          balloonContentHeader: "Смотровая площадка",
          balloonContentBody: "Орловскаяская область, Мценский район, с.Черемошны",
          // balloonContentFooter: "(ПВХ)",
          hintContent: "Смотровая площадка"
        }));
        myMap.geoObjects.add(new maps.Placemark([51.788660, 41.439329], {
          balloonContentHeader: "Смотровая площадка",
          balloonContentBody: "Тамбовская область, Жердевский район, с.Новорусаново",
          // balloonContentFooter: "(МТС)",
          hintContent: "Смотровая площадка"
        }));
        myMap.geoObjects.add(new maps.Placemark([52.411203, 41.445204], {
          balloonContentHeader: "Смотровая площадка",
          balloonContentBody: "Тамбовская область, Знаменский район, с.Знаменка",
          // balloonContentFooter: "(МТС)",
          hintContent: "Смотровая площадка"
        }));
        myMap.geoObjects.add(new maps.Placemark([53.231074, 40.693314], {
          balloonContentHeader: "Смотровая площадка",
          balloonContentBody: "Тамбовская область, Староюрьевский район, с.Вишневое",
          // balloonContentFooter: "(МТС)",
          hintContent: "Смотровая площадка"
        }));

      })
      .catch(error => console.log('Failed to load Yandex Maps', error));
  }

}
