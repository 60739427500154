import { Component, OnInit } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig]
})
export class HomeComponent implements OnInit {

  base = environment.apiUrl;
  photos = [this.base+'/Files/promo__1.png',this.base+'/Files/promo__2.png',this.base+'/Files/promo__3.png',this.base+'/Files/promo__4.png'];
  constructor(config: NgbCarouselConfig) {
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
   }

  ngOnInit() {
    console.log('config: '+this.base);
    document.getElementById("nav-footer").style.visibility = 'visible';
  }

}
