import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lot } from '../Lot';
import { ManagerService } from '../manager.service';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UniversalModalComponent } from '../universal-modal/universal-modal.component';

@Component({
  selector: 'app-create-lot',
  templateUrl: './create-lot.component.html',
  styleUrls: ['./create-lot.component.scss']
})
export class CreateLotComponent implements OnInit {

  lot: Lot;
  modalRefUniversal: NgbModalRef;

  constructor(private route: ActivatedRoute,private router: Router, private managerService: ManagerService, private modalService: NgbModal) { }

  ngOnInit() {
    this.lot = new Lot();
  }
  onSubmit() {
    this.managerService.insertLot(this.lot)
    .subscribe(data => {
      this.modalRefUniversal = this.modalService.open(UniversalModalComponent, { backdrop: 'static', keyboard: false, size: 'sm' });
      this.modalRefUniversal.componentInstance.isSuccess = true;
      this.modalRefUniversal.componentInstance.closeNavButtonEnabled = true;
      this.modalRefUniversal.componentInstance.footerEnabled = true;
      this.modalRefUniversal.componentInstance.message = 'Лот создан';
      this.modalRefUniversal.componentInstance.title = 'Выполнено';
      this.modalRefUniversal.componentInstance.navigateTo = '/manager';
    }, error => {
      this.modalRefUniversal = this.modalService.open(UniversalModalComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
      this.modalRefUniversal.componentInstance.isDanger = true;
      this.modalRefUniversal.componentInstance.closeButtonEnabled = true;
      this.modalRefUniversal.componentInstance.footerEnabled = true;
      this.modalRefUniversal.componentInstance.message = 'Не удалось создать лот. Возможной причиной может быть попытка добавить лот с регионом, непринадлежащим вашей учетной записи. Так же проверьте корректность вносимых данных.';
      this.modalRefUniversal.componentInstance.title = 'Ошибка';
    });
     
  }
  gotoList() {
    this.router.navigate(['/manager']); // Remove
  }
  cancel(event: any) {
    this.router.navigate(['/manager']);
  }


}
