import { Component, OnInit } from '@angular/core';
import { NgForm} from '@angular/forms';
import { ManagerService } from '../manager.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  invalidLogin: boolean = false;

  constructor(private router: Router, private managerService: ManagerService) { }

  ngOnInit() {
  }
  
  login(form: NgForm) {
    this.managerService.login(form).subscribe(response => {
      let token = (<any>response).token;
      localStorage.setItem("jwt", token);
      this.invalidLogin = false;
      this.router.navigate(["manager"]);
    }, err => {
      this.invalidLogin = true;
    });
  }
  logOut() {
    localStorage.removeItem("jwt");
 }
}
