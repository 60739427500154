import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Lot } from './Lot';
import { Auction } from './Auction';
import { environment } from './../environments/environment';

@Injectable()
export class ManagerService {

  baseUrl: string = environment.apiUrl;
  constructor(private http: HttpClient) { }

  // getLots(): Observable<any> {
  //   return this.http.get(this.baseUrl + `/api/values`, { observe: 'body' })
  // }
  getLots(): Observable<Lot[]> {
    return this.http.get(this.baseUrl + `/api/values`).pipe(map(data => {
      let lots: Lot[] = data as Lot[];
      return lots;
    }));
  }
  getLotsByUser(username): Observable<Lot[]> {
    return this.http.get(this.baseUrl + `/api/values/byuser/${username}`).pipe(map(data => {
      let lots: Lot[] = data as Lot[];
      return lots;
    }));
  }

  getLot(id): Observable<any> {
    return this.http.get(this.baseUrl + `/api/values/${id}`, { observe: 'body' })

  }
  getChildLots(id): Observable<any> {
    return this.http.get(this.baseUrl + `/api/values/childlots/${id}`, { observe: 'body' })
  }

  insertLot(value: any): Observable<Object> { // Отправляет даные нового лота
    let fd = new FormData;
    let token = localStorage.getItem("jwt");
    let postHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    postHeaders.append("Content-Type", "application/json");
    fd.append('value', JSON.stringify(value));
    return this.http.post(this.baseUrl + `/api/values`, fd, { headers: postHeaders });
  }

  updateLot(id: number, value: any): Observable<Object> {
    let fd = new FormData;
    let token = localStorage.getItem("jwt");
    let postHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    postHeaders.append("Content-Type", "application/json");
    fd.append('value', JSON.stringify(value));
    return this.http.put(this.baseUrl + `/api/values/${id}`, fd, { headers: postHeaders });
  }

  deleteLot(id: number): Observable<Object> {
    let token = localStorage.getItem("jwt");
    let postHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    postHeaders.append("Content-Type", "application/json");
    return this.http.delete(this.baseUrl + `/api/values/${id}`, { headers: postHeaders });
  }
  deletePhoto(filename: string): Observable<Object> {
    let token = localStorage.getItem("jwt");
    let postHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    postHeaders.append("Content-Type", "application/json");
    return this.http.delete(this.baseUrl + `/api/files/${filename}`, { headers: postHeaders });
  }

  getFiles(): Observable<any> {
    return this.http.get(this.baseUrl + `/api/files`, { observe: 'body' })
  }
  uploadFile(fd: FormData) {
    console.log(environment.apiUrl);
    return this.http.post(this.baseUrl + `/api/files`, fd).subscribe(res => { if (res != null) alert('Файлы загружены'); console.log(res) }, error => alert('Ошбика при загрузке файла'));
  }

  login(form: NgForm): Observable<any> {
    let credentials = JSON.stringify(form.value);
    return this.http.post(this.baseUrl + `/api/login`, credentials, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    });
  }

  getUsers() {
    let token = localStorage.getItem("jwt");
    let requestHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    requestHeaders.append("Content-Type", "application/json");
    return this.http.get(this.baseUrl + `/api/GetSystemUsers`, { headers: requestHeaders });
  }
  getUserRegionsCodes(name: string): Observable<any> {
    let token = localStorage.getItem("jwt");
    let requestHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    requestHeaders.append("Content-Type", "application/json");
    return this.http.get(this.baseUrl + `/api/GetUserRegionsCodes/${name}`, { headers: requestHeaders });
  }
  updateUser(name, password, regions: string) {
    let fd = new FormData;
    let token = localStorage.getItem("jwt");
    let postHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    postHeaders.append("Content-Type", "application/json");
    fd.append('name', name);
    fd.append('password', password);
    fd.append('regions', regions);
    return this.http.put(this.baseUrl + `/api/UpdateUser`, fd, { headers: postHeaders });
  }
  createUser(name: string, password: string, regions: string): Observable<any> {
    let fd = new FormData;
    let token = localStorage.getItem("jwt");
    let postHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    postHeaders.append("Content-Type", "application/json");
    fd.append('name', name);
    fd.append('password', password);
    fd.append('regions', regions);
    return this.http.post(this.baseUrl + `/api/CreateUser`, fd, { headers: postHeaders });
  }
  disableUser(name: string) {
    let fd = new FormData;
    let token = localStorage.getItem("jwt");
    let postHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    postHeaders.append("Content-Type", "application/json");
    fd.append('name', name);
    return this.http.put(this.baseUrl + `/api/disableUser`, fd, { headers: postHeaders });
  }
  enableUser(name: string) {
    let fd = new FormData;
    let token = localStorage.getItem("jwt");
    let postHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    postHeaders.append("Content-Type", "application/json");
    fd.append('name', name);
    return this.http.put(this.baseUrl + `/api/enableUser`, fd, { headers: postHeaders });
  }


  getRegions(): Observable<any> {
    return this.http.get(this.baseUrl + `/api/values/regions`, { observe: 'body' })
  }
  getCategories(): Observable<any> {
    return this.http.get(this.baseUrl + `/api/values/categories`, { observe: 'body' })
  }
  getPlaces(): Observable<any> {
    return this.http.get(this.baseUrl + `/api/values/places`, { observe: 'body' })
  }
  getVendors(): Observable<any> {
    return this.http.get(this.baseUrl + `/api/values/vendors`, { observe: 'body' })
  }
  getModels(): Observable<any> {
    return this.http.get(this.baseUrl + `/api/values/models`, { observe: 'body' })
  }

  getParts(): Observable<any> {
    return this.http.get(this.baseUrl + `/api/parts`, { observe: 'body' })
  }
  getPartsByFilter(title: string, fits: string, region: string): Observable<any> {
    let fd = new FormData;
    fd.append('title', title);
    fd.append('fits', fits);
    fd.append('region', region);
    return this.http.post(this.baseUrl + `/api/parts`, fd);
  }


  getEvents(): Observable<any> { // Получить список аукционов
    return this.http.get(this.baseUrl + `/api/biddingevents`);
  }
  getEventsByRegion(id: number): Observable<any> { // Получить список аукционов
    let token = localStorage.getItem("jwt");
    let requestHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    requestHeaders.append("Content-Type", "application/json");
    return this.http.get(this.baseUrl + `/api/biddingevents/${id}`, { headers: requestHeaders, observe: 'body' })
  }
  // Получить список аукционов по кодам регионов
  getEventsByRegionCodes(regions: string): Observable<any> { 
    return this.http.get(this.baseUrl + `/api/biddingevents/v2/${regions}`)
  }
  // Список Регионов, в которых проводятся торги
  getEventsRegions(): Observable<any> {
    return this.http.get(this.baseUrl + `/api/biddingevents/regions`);
  }

  uploadEventFile(fd: FormData): Observable<any> {
    let token = localStorage.getItem("jwt");
    let requestHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    requestHeaders.append("Content-Type", "application/json");
    return this.http.post(this.baseUrl + `/api/biddingevents`, fd, { headers: requestHeaders })
  }

  // ФОТО
  getPhotosByNumber(number: string) {
    console.log('LotNumber:' + number)
    let token = localStorage.getItem("jwt");
    let requestHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    requestHeaders.append("Content-Type", "application/json");
    return this.http.get(this.baseUrl + `/api/files/${number}`, { headers: requestHeaders, observe: 'body' })
  }
  rotatePhotobyName(filename: string): Observable<Object> {
    let token = localStorage.getItem("jwt");
    let postHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    postHeaders.append("Content-Type", "application/json");
    return this.http.put(this.baseUrl + `/api/files/${filename}`, { headers: postHeaders });
  }
  rotatePhotobyName90(filename: string): Observable<Object> {
    let token = localStorage.getItem("jwt");
    let postHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    postHeaders.append("Content-Type", "application/json");
    return this.http.put(this.baseUrl + `/api/files/r90/${filename}`, { headers: postHeaders });
  }
  rotatePhotobyName270(filename: string): Observable<Object> {
    let token = localStorage.getItem("jwt");
    let postHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    postHeaders.append("Content-Type", "application/json");
    return this.http.put(this.baseUrl + `/api/files/r270/${filename}`, { headers: postHeaders });
  }
  getLogs(id: string): Observable<any> {
    let token = localStorage.getItem("jwt");
    let requestHeaders = new HttpHeaders().append("Authorization", "Bearer " + token);
    requestHeaders.append("Content-Type", "application/json");
    return this.http.get(this.baseUrl + `/api/values/logs/${id}`, { headers: requestHeaders });
  }



}