import { Component, Input  } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManagerService } from '../../manager.service';

@Component({
  selector: 'app-log-modal',
  templateUrl: './log-modal.component.html',
  styleUrls: ['./log-modal.component.scss']
})
export class LogModalComponent{
  @Input() Id: number;

  logs: any [] = []

  constructor(public activeModal: NgbActiveModal, private managerService: ManagerService) { }

  ngOnInit() {
    // Запросить логи по ID-лота!
    this.managerService.getLogs(this.Id.toString()).subscribe(res => {
      this.logs = res;
      console.log(this.logs);
    }, error => {
      console.log('Ошибка при получении logs!');
    });
  }

}
