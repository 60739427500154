import { Component, OnInit } from '@angular/core';
import { Auction } from '../Auction';
import { ManagerService } from '../manager.service';
import { FormsModule, FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-bidding',
  templateUrl: './bidding.component.html',
  styleUrls: ['./bidding.component.scss']
})
export class BiddingComponent implements OnInit {

  isCollapsed = true;
  auctions: Auction[];
  allRegions: any[]; // Все регионы
  selectedRegions: any = []; // Выбранные регионы
  regionGroup: FormGroup;

  dropdownRegionSettings: any = {};

  page = 1;
  pageSize: number = 6;
  constructor(private managerService: ManagerService) {
    this.auctions = new Array();
    this.selectedRegions = new Array();
  }

  ngOnInit() {

    this.dropdownRegionSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Выбрать все',
      searchPlaceholderText: 'Поиск',
      unSelectAllText: 'Убрать все',
      itemsShowLimit: 2,
      allowSearchFilter: false,
      enableCheckAll: false
    };
    // Получим список доступных регионов по аукционам
    this.managerService.getEventsRegions().subscribe(res => {
      this.allRegions = res;
    });
    this.managerService.getEvents().subscribe(res => {
      this.auctions = res as Auction[];
    }, error => {
      console.log('Ошибка при получении списка аукционов!' + error);
    });
  }
  onRegionSelect(item: any) {
    this.managerService.getEventsByRegion(item).subscribe(res => {
      this.auctions = res as Auction[];
    }
    );
  }
  onRegionDeSelect(item: any) {
    this.selectedRegions = [];
    this.managerService.getEvents().subscribe(res => {
      this.auctions = res as Auction[];
    }, error => {
      console.log('Ошибка при получении списка аукционов!' + error);
    });
  }

}
