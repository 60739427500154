import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lot } from '../Lot';
import { ManagerService } from '../manager.service';
import { map } from 'rxjs/operators';
import { JsonPipe } from '@angular/common';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UniversalModalComponent } from '../universal-modal/universal-modal.component';

@Component({
  selector: 'app-update-lot',
  templateUrl: './update-lot.component.html',
  styleUrls: ['./update-lot.component.scss']
})
export class UpdateLotComponent implements OnInit {
  baseUrl: string = environment.apiUrl;
  id: number;
  lot: Lot;
  photos: any[];
  filtersLoaded: Promise<boolean>;
  modalRefUniversal: NgbModalRef;
  constructor(private route: ActivatedRoute, private router: Router, private managerService: ManagerService, private modalService: NgbModal) { }

  ngOnInit() {
    this.lot = new Lot();
    this.id = this.route.snapshot.params['id'];

    this.managerService.getLot(this.id)
      .subscribe(data => {
        console.log(data)
        this.lot = data;
        // фолучим все фотки
        this.managerService.getPhotosByNumber(this.lot.Number).subscribe(data => {
          this.filtersLoaded = Promise.resolve(true);
          this.photos = data as any[];
          this.photos.forEach(function(part, index) {
            this[index] += "?="+Math.random();
          }, this.photos);
          // thumbArray.forEach(function (value: HTMLElement) {
          //   console.log(value.clientHeight + 'x' + value.clientWidth);
          // }
          // );
        }, error => console.log(error));
      }, error => console.log(error));
  }

  onSubmit() {
    this.managerService.updateLot(this.id, this.lot)
      .subscribe(data => { this.gotoList(); console.log(data); }, error => console.log(error));
    this.lot = new Lot();
  }
  gotoList() {
    this.router.navigate(['/manager']);
  }
  cancel(event: any) {
    this.router.navigate(['/manager']);
  }

  rotateLeft(event, filename) {
    this.managerService.rotatePhotobyName90(filename).subscribe(data => {
      //let img: HTMLElement = event.target as HTMLElement;
      let tr = event.target.parentElement.parentElement as HTMLElement;
      let img = tr.previousSibling.firstChild.firstChild.firstChild as HTMLElement;

      let angle: number = parseInt(img.getAttribute('data-angle'));
      switch (angle) {
        case 0: {
          img.setAttribute('data-angle', '90');
          img.className = 'thumbnail rotate90';
          break;
        }
        case 90: {
          img.setAttribute('data-angle', '180');
          img.className = 'thumbnail rotate180';
          break;
        }
        case 180: {
          img.setAttribute('data-angle', '270');
          img.className = 'thumbnail rotate270';
          break;
        }
        case 270: {
          img.setAttribute('data-angle', '0');
          img.className = 'thumbnail';
          break;
        }
      }
    }, error => console.log(error));

  }

  rotateRight(event, filename) {
    this.managerService.rotatePhotobyName270(filename).subscribe(data => {
      let tr = event.target.parentElement.parentElement as HTMLElement;
      let img = tr.previousSibling.firstChild.firstChild.firstChild as HTMLElement;
      let angle: number = parseInt(img.getAttribute('data-angle'));
      switch (angle) {
        case 0: {
          img.setAttribute('data-angle', '270');
          img.className = 'thumbnail rotate270';
          break;
        }
        case 90: {
          img.setAttribute('data-angle', '0');
          img.className = 'thumbnail';
          break;
        }
        case 180: {
          img.setAttribute('data-angle', '90');
          img.className = 'thumbnail rotate90';
          break;
        }
        case 270: {
          img.setAttribute('data-angle', '180');
          img.className = 'thumbnail rotate180';
          break;
        }
      }
    }, error => console.log(error));
  }
  tryRemovePhoto(event, filename) {
    this.modalRefUniversal = this.modalService.open(UniversalModalComponent, { backdrop: 'static', keyboard: false, size: 'sm', centered: true });
    this.modalRefUniversal.componentInstance.isWarning = true;
    this.modalRefUniversal.componentInstance.footerEnabled = true;
    this.modalRefUniversal.componentInstance.message = 'Вы действительно хотите удалить фото \"' + filename + '\" ?';
    this.modalRefUniversal.componentInstance.title = 'Внимание';
    this.modalRefUniversal.componentInstance.valueToConfirm = filename;
    this.modalRefUniversal.componentInstance.closeButtonEnabled = false;
    this.modalRefUniversal.componentInstance.confirmButtonEnabled = true;
    this.modalRefUniversal.result.then(confirmed => {
      this.managerService.deletePhoto(filename).subscribe(success => {

        this.managerService.getPhotosByNumber(this.lot.Number).subscribe(data => {
          this.filtersLoaded = Promise.resolve(true);
          this.photos = data as any[];
          this.modalRefUniversal = this.modalService.open(UniversalModalComponent, { backdrop: 'static', keyboard: false, size: 'sm', centered: true });
          this.modalRefUniversal.componentInstance.isSuccess = true;
          this.modalRefUniversal.componentInstance.footerEnabled = true;
          this.modalRefUniversal.componentInstance.message = 'Фото \"' + filename + '\" удалено';
          this.modalRefUniversal.componentInstance.title = 'Выполнено';
          this.modalRefUniversal.componentInstance.closeButtonEnabled = true
        });
      }, error => {
        this.modalRefUniversal = this.modalService.open(UniversalModalComponent, { backdrop: 'static', keyboard: false, size: 'sm', centered: true });
        this.modalRefUniversal.componentInstance.isDanger = true;
        this.modalRefUniversal.componentInstance.footerEnabled = true;
        this.modalRefUniversal.componentInstance.message = 'Не удалось удалить фото \"' + filename + '\"';
        this.modalRefUniversal.componentInstance.title = 'Ошибка';
        this.modalRefUniversal.componentInstance.closeButtonEnabled = true;
        console.log(error);
      });
    },dismiss=>{console.log("Отмена");});

  }

}
