import { Component, OnInit } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { FormsModule, FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ManagerService } from '../manager.service';
import { Lot } from '../Lot';
import { environment } from '../../environments/environment';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';
import { element } from 'protractor';
import { LotDetailsModalComponent } from '../lot-details-modal/lot-details-modal.component';
import { stringify } from 'querystring';
import { randomBytes } from 'crypto';

export class LotGallery {
  lotId: number;
  galleryId: number;
}

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})


export class FilterComponent implements OnInit {

  base = environment.apiUrl;
  show: boolean = true;
  hideSquareClass: string = 'd-none';
  hideMileageClass: string = 'd-none';
  hideVendorModelClass: string = 'd-none';

  lots: any = []; // Список всех лотов
  allLots: Lot[]; // Все лоты из API
  allFiles: any[]; // все картинки из API

  lotGalleriesIds: LotGallery[] = [];

  filteredLots: any = []; // Список отфильрованных лотов

  page = 1;
  pageSize: number = 6;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings: any = {};
  dropdownPlaceSettings: any = {};
  dropdownVendorSettings: any = {};
  dropdownModelSettings: any = {};
  categoryList = [];
  FilterCategorySettings: any = {};

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  galleries: any[] = [];
  filteredGalleries: any[] = [];

  searchString: string = '';

  priceFrom: number = 0;
  priceTo: number = 0;

  yearFrom: number = 0;
  yearTo: number = 0;

  isPriceChanged = false;
  isYearChanged = false;
  isSquareChanged = false;
  isMileageChanged = false;

  squareFrom: number = 0;
  squareTo: number = 0;

  mileageFrom: number = 0;
  mileageTo: number = 0;

  searchGroup: FormGroup;
  myGroup1: FormGroup;
  myGroup2: FormGroup;
  myGroup3: FormGroup;
  myGroup4: FormGroup;

  allRegions: any = []; // Все регионы
  selectedRegions: any = []; // Выбранные регионы
  allCategories: any = []; // Все категории
  selectedCategories: any = []; // Выбранные категории
  allPlaces: any = []; // Все площадки
  regionsPlaces: any = []; // Площадки выбранных регионов
  selectedPlaces: any = []; // Выбранные площадки
  allVendors: any = []; // Все марки
  categoriesVendors: any = []; // Все марки категории
  selectedVendors: any = []; // Выбранные марки
  allModels: any = []; // Все модели
  vendorsModels: any = []; // Все модели марки
  selectedModels: any = []; // Выбранные модели

  constructor(private managerService: ManagerService, private modalService: NgbModal) { }

  ngOnInit() {

    this.show = this.filteredLots.length == 0;
    this.searchGroup = new FormGroup({
      searchText: new FormControl()
    });
    this.myGroup1 = new FormGroup({
      Region: new FormControl()
    });
    this.myGroup2 = new FormGroup({
      category: new FormControl()
    });
    this.myGroup3 = new FormGroup({
      Place: new FormControl()
    });
    this.myGroup4 = new FormGroup({
      Vendor: new FormControl(),
      Model: new FormControl()
    });

    document.getElementById("nav-footer").style.visibility = 'hidden';

    this.managerService.getRegions().subscribe(res => {
      this.allRegions = res;
      //console.log(this.allRegions)
    }); // Подгрузим регионы
    this.managerService.getCategories().subscribe(res => {
      this.allCategories = res;
      //console.log(this.allCategories)
    }); // Подгрузим категории
    this.managerService.getPlaces().subscribe(res => {
      this.allPlaces = res;
      this.regionsPlaces = this.allPlaces; // Считаем, что площадки выбраны по всем регионам
      //console.log(this.allPlaces)
    }); // Подгрузим площадки
    this.managerService.getVendors().subscribe(res => {
      this.allVendors = res;
      this.categoriesVendors = this.allVendors;
      //console.log(this.allVendors)
    }); // Подгрузим марки
    this.managerService.getModels().subscribe(res => {
      this.allModels = res;
      this.vendorsModels = this.allModels;
      //console.log(this.allModels)
    }); // Подгрузим марки

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Выбрать все',
      searchPlaceholderText: 'Поиск',
      unSelectAllText: 'Убрать все',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.FilterCategorySettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Выбрать все',
      unSelectAllText: 'Убрать все',
      searchPlaceholderText: 'Поиск',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.dropdownPlaceSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Выбрать все',
      searchPlaceholderText: 'Поиск',
      unSelectAllText: 'Убрать все',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    this.dropdownModelSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Выбрать все',
      searchPlaceholderText: 'Поиск',
      unSelectAllText: 'Убрать все',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Не выбрана марка'
    };
    this.dropdownVendorSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Выбрать все',
      searchPlaceholderText: 'Поиск',
      unSelectAllText: 'Убрать все',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    // Получаем все лоты
    this.managerService.getLots()
      .subscribe(res => {
        this.allLots = res as Lot[];
        this.allLots.forEach(element => {
          let str = element.Title.split(" ");
          element.Title = str.length > 2? str[0] + ' ' + str[1]: str[0];
        });
        this.filteredLots = this.allLots
        console.log(this.allLots);
        for (let i = 0; i < this.filteredLots.length; i++) {
          let photoList: any[] = [];
          for (let j = 0; j < this.filteredLots[i].Photos.length; j++) {
            let photo: any = { small: this.base + '/Files/' + this.filteredLots[i].Photos[j], medium: this.base + '/Files/' + this.filteredLots[i].Photos[j], big: this.base + '/Files/' + this.filteredLots[i].Photos[j] };
            photoList.push(photo);
          }
          this.filteredLots[i].Photos = photoList;
        }
        console.log(this.filteredLots);
      });

    this.galleryOptions = [
      { "previewKeyboardNavigation": true, thumbnailsColumns: 1, "image": false, "width": "100%", "height": "175px", "thumbnailsArrows": false, "previewRotate": true, previewInfinityMove: true }
    ];

  }
  finalFilter(): any {
    let resultArr: Lot[];


    if (this.selectedRegions.length > 0) {
      resultArr = this.allLots.filter(lot => this.selectedRegions.includes(lot.Region));
      this.regionsPlaces = this.allPlaces.filter(place => resultArr.map(element => { return element.Place }).includes(place));  // Площадки!
      console.log(this.regionsPlaces);
    }
    else {
      resultArr = this.allLots.filter(lot => this.allRegions.includes(lot.Region));
      this.regionsPlaces = this.allPlaces; // все регионы = все площадки
    }
    if (this.selectedCategories.length > 0) {
      resultArr = resultArr.filter(lot => this.selectedCategories.includes(lot.CategoryType));
      this.categoriesVendors = this.allVendors.filter(title => resultArr.map(element => { return element.Vendor }).includes(title));// Марки!!! 
      this.vendorsModels = []; // Марка не выбрана = список моделей недоступен !!!!    
    }
    else {
      resultArr = resultArr.filter(lot => this.allCategories.includes(lot.CategoryType));
      this.categoriesVendors = this.allVendors;
      this.vendorsModels = []; // Марка не выбрана = список моделей недоступен !!!!
    }
    if (this.selectedPlaces.length > 0) {
      resultArr = resultArr.filter(lot => this.selectedPlaces.includes(lot.Place));
    }
    else {
      resultArr = resultArr.filter(lot => this.allPlaces.includes(lot.Place));
    }

    if (this.selectedVendors.length > 0) {
      resultArr = resultArr.filter(lot => this.selectedVendors.includes(lot.Vendor));
      this.vendorsModels = this.allModels.filter(title => resultArr.map(element => { return element.Model }).includes(title));// Модели !!! 
    }
    if (this.selectedModels.length > 0) {
      resultArr = resultArr.filter(lot => this.selectedModels.includes(lot.Model));
    }

    if (!this.isPriceChanged) {
      let prices: number[] = resultArr.map(element => { return element.Price });
      this.priceFrom = Math.min.apply(null, prices);
      this.priceTo = Math.max.apply(null, prices);
    }


    if (!this.isYearChanged) {
      let years: number[] = resultArr.map(element => { return element.Year });
      this.yearFrom = Math.min.apply(null, years);
      this.yearTo = Math.max.apply(null, years);
    }

    if (!this.isSquareChanged) {
      let squares: number[] = resultArr.map(element => { return parseInt(element.Square) });
      this.squareFrom = Math.min.apply(null, squares);
      this.squareTo = Math.max.apply(null, squares);
    }

    if (!this.isMileageChanged) {
      let mileages: number[] = resultArr.map(element => { return parseInt(element.Mileage) });
      this.mileageFrom = Math.min.apply(null, mileages);
      this.mileageTo = Math.max.apply(null, mileages);
    }

    if ((this.priceFrom > 0) && (this.priceFrom <= this.priceTo))
      resultArr = resultArr.filter(lot => (lot.Price >= this.priceFrom) && (lot.Price <= this.priceTo));

    if ((this.yearFrom > 0) && (this.yearFrom <= this.yearTo))
      resultArr = resultArr.filter(lot => (lot.Year >= this.yearFrom) && (lot.Year <= this.yearTo));
    if ((this.squareFrom > 0) && (this.squareFrom <= this.squareTo))
      resultArr = resultArr.filter(lot => (parseInt(lot.Square) >= this.squareFrom) && (parseInt(lot.Square) <= this.squareTo));
    if ((this.mileageFrom > 0) && (this.mileageFrom <= this.mileageTo))
      resultArr = resultArr.filter(lot => (parseInt(lot.Mileage) >= this.mileageFrom) && (parseInt(lot.Mileage) <= this.mileageTo));

    if (this.searchString.length > 0) {
      console.log(this.searchString)
      resultArr = this.allLots.filter(lot => lot.Title.toUpperCase().includes(this.searchString.toUpperCase()) || lot.Number.toUpperCase().includes(this.searchString.toUpperCase()));
    }

    // Откроем скрытый раздел Марка-Модель, если недвижимость не выбрана
    this.hideMileageClass = this.selectedCategories.includes('Недвижимость') ? 'd-none' : '';
    this.hideSquareClass = this.selectedCategories.includes('Недвижимость') && this.selectedCategories.length == 1 ? '' : 'd-none';
    this.hideVendorModelClass = !this.selectedCategories.includes('Недвижимость') ? this.hideMileageClass : 'd-none';


    this.isYearChanged = false;
    this.isPriceChanged = false;
    this.isSquareChanged = false;
    this.isMileageChanged = false;
    //console.log(resultArr);

    return resultArr;
  }
  onRegionSelect(item: any) {
    this.selectedRegions.push(item); // Когда выбираем - помещаем в массив выбранных
    this.filteredLots = this.finalFilter();
    //console.log('onItemSelect', this.selectedRegions);
    // А потом фильруем согласно массиву выбранных
    //this.filteredLots = this.allLots.filter(lot => this.selectedRegions.includes(lot.Region));
    // Нужно учитывать другие параметры, прежде чем выводить в filtered
    //console.log('onItemSelect', this.filteredLots);
  }
  onRegionDeSelect(item: any) {
    this.selectedRegions = this.selectedRegions.filter(region => region != item);
    this.filteredLots = this.finalFilter();
    //console.log('onDeSelect', this.selectedRegions);
    //this.filteredLots = this.allLots.filter(lot => this.selectedRegions.includes(lot.Region));
    //console.log('onDeSelect', this.filteredLots);
  }
  onRegionSelectAll(items: any) {
    items.forEach(element => {
      this.selectedRegions.push(element);
    });
    this.filteredLots = this.finalFilter();
    //this.filteredLots = this.allLots.filter(lot => this.selectedRegions.includes(lot.Region));
    //console.log('onSelectAll', items);
  }
  onRegionDeselectAll(items: any) {
    this.selectedRegions = [];
    //console.log('onDeSelectAll', this.selectedRegions);
    this.filteredLots = this.finalFilter();
    //console.log('onDeSelectAll', this.filteredLots);
  }

  onFilterCategorySelect(item: any) {
    this.selectedCategories.push(item);
    this.filteredLots = this.finalFilter();
    //console.log('onItemSelect', this.selectedRegions);
    //this.filteredLots = this.allLots.filter(lot => this.selectedCategories.includes(lot.CategoryType));
    //console.log('onFilterCategorySelect', this.selectedCategories);
  }
  onFilterCategoryDeSelect(item: any) {
    this.selectedCategories = this.selectedCategories.filter(category => category != item);
    this.filteredLots = this.finalFilter();
    //console.log('onDeSelectCategory', this.selectedCategories);
    //this.filteredLots = this.allLots.filter(lot => this.selectedCategories.includes(lot.CategoryType));
    //console.log('onDeSelectCategory', this.filteredLots);
  }
  onFilterCategorySelectAll(items: any) {
    items.forEach(element => {
      this.selectedCategories.push(element);
    });
    this.filteredLots = this.finalFilter();
    //this.filteredLots = this.allLots.filter(lot => this.selectedCategories.includes(lot.CategoryType));
  }
  onFilterCategoryDeselectAll(items: any) {
    this.selectedCategories = [];
    this.filteredLots = this.finalFilter();
  }
  onPlaceSelect(item: any) {
    this.selectedPlaces.push(item);
    this.filteredLots = this.finalFilter();
    //console.log('onItemSelect', this.selectedRegions);
    //this.filteredLots = this.allLots.filter(lot => this.selectedPlaces.includes(lot.Place));
    //console.log('onFilterCategorySelect', this.selectedCategories);
  }
  onPlaceDeSelect(item: any) {
    this.selectedPlaces = this.selectedPlaces.filter(place => place != item);
    this.filteredLots = this.finalFilter();
    //console.log('onDeSelectCategory', this.selectedCategories);
    //this.filteredLots = this.allLots.filter(lot => this.selectedCategories.includes(lot.Place));
    //console.log('onDeSelectCategory', this.filteredLots);
  }
  onPlaceSelectAll(items: any) {
    items.forEach(element => {
      this.selectedPlaces.push(element);
    });
    this.filteredLots = this.finalFilter();
    //this.filteredLots = this.allLots.filter(lot => this.selectedPlaces.includes(lot.Place));
  }
  onPlaceDeselectAll(items: any) {
    this.selectedPlaces = [];
    this.filteredLots = this.finalFilter();
  }

  onVendorSelect(item: any) {
    this.selectedVendors.push(item);
    this.filteredLots = this.finalFilter();
  }
  onVendorDeSelect(item: any) {
    this.selectedVendors = this.selectedVendors.filter(Vendor => Vendor != item);
    this.filteredLots = this.finalFilter();
  }
  onVendorSelectAll(items: any) {
    items.forEach(element => {
      this.selectedVendors.push(element);
    });
    this.filteredLots = this.finalFilter();
  }
  onVendorDeselectAll(items: any) {
    this.selectedVendors = [];
    this.filteredLots = this.finalFilter();
  }

  onModelSelect(item: any) {
    this.selectedModels.push(item);
    this.filteredLots = this.finalFilter();
  }
  onModelDeSelect(item: any) {
    this.selectedModels = this.selectedModels.filter(Model => Model != item);
    this.filteredLots = this.finalFilter();
  }
  onModelSelectAll(items: any) {
    items.forEach(element => {
      this.selectedModels.push(element);
    });
    this.filteredLots = this.finalFilter();
  }
  onModelDeselectAll(items: any) {
    this.selectedModels = [];
    this.filteredLots = this.finalFilter();
  }

  onDropDownClose() {
    console.log('Closed');
  }
  checkShow() {
    this.show = this.filteredLots == 0;
  }
  onPriceChange() {
    this.isPriceChanged = true;
    this.filteredLots = this.finalFilter();
    console.log(this.priceFrom);
    console.log(this.priceTo);
  }
  onYearChange() {
    this.isYearChanged = true;
    this.filteredLots = this.finalFilter();
    console.log(this.yearFrom);
    console.log(this.yearTo);
  }
  onSquareChange() {
    this.isSquareChanged = true;
    this.filteredLots = this.finalFilter();
    console.log(this.squareFrom);
    console.log(this.squareTo);
  }
  onMileageChange() {
    this.isMileageChanged = true;
    this.filteredLots = this.finalFilter();
    console.log(this.mileageFrom);
    console.log(this.mileageTo);
  }
  getLotIdByGalleryId(id: number) {
    return this.lotGalleriesIds.find(item => item.galleryId == id).lotId;
  }
  getGalleryIdByLotId(id: number) {
    return this.lotGalleriesIds.find(item => item.lotId == id).galleryId;
  }

  showLotDetails(lot: Lot) {
    const modalRef = this.modalService.open(LotDetailsModalComponent, { size: "lg" });
    modalRef.componentInstance.lot = lot;
    modalRef.componentInstance.gallery = lot.Photos;
    // Найдем дочерние лоты
    this.managerService.getChildLots(lot.Number).subscribe(res=>{
      let childs = res as Lot[];
      modalRef.componentInstance.hasChildLots = childs.length > 0;
      modalRef.componentInstance.childLots = res;
    })  
  }

  getFirstThumb(lot: Lot) {
    let photos: { small: string, medium: string, big: string }[] = lot.Photos;
    //console.log(lot.Photos);
    if (photos.length > 0)
      return photos[0].small;
    return "";
  }
  searchByText(event: any) {
    this.searchString = event.target.value;
    this.filteredLots = this.finalFilter();
  }
  onClearFilter() {
    this.selectedRegions = [];
    this.selectedCategories = [];
    this.selectedPlaces = [];
    this.priceTo = 0;
    this.priceFrom = 0;
    this.yearFrom = 0;
    this.yearTo = 0;
    this.mileageFrom = 0;
    this.mileageTo = 0;
    this.squareFrom = 0;
    this.squareTo = 0;
    this.selectedVendors = [];
    this.selectedModels = [];
    this.filteredLots = this.allLots;
    this.searchGroup.controls['searchText'].setValue('');
  }
}