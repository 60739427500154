import { Component, OnInit, EventEmitter } from '@angular/core';
import { Input, Output } from '@angular/core';
import {NgbActiveModal, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-universal-modal',
  templateUrl: './universal-modal.component.html',
  styleUrls: ['./universal-modal.component.scss']
})
export class UniversalModalComponent implements OnInit {
  @Input() footerEnabled: boolean = true;
  
  @Input() isPreloaderEnabled: boolean = false;
  @Input() isWarning: boolean = false;
  @Input() isDanger: boolean = false;
  @Input() isNotification: boolean = false;
  @Input() isSuccess: boolean = false;
  @Input() closeButtonEnabled: boolean = false;
  @Input() confirmButtonEnabled: boolean = false;

  @Input() message: string = '';
  @Input() title: string = '';

  @Input() navigateTo: string = '';

  constructor(public activeModal: NgbActiveModal, private router: Router) {}
  ngOnInit() {
  }
  closeNav() {
    this.router.navigate([this.navigateTo]);
    this.activeModal.close();
  }

}
