import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ManagerService } from '../manager.service';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Lot } from '../Lot';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UniversalModalComponent } from '../universal-modal/universal-modal.component';
import { LogModalComponent } from './log-modal/log-modal.component';
import { ErrorModalComponent } from './error-modal/error-modal/error-modal.component';
import { ThrowStmt } from '@angular/compiler';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { resolve } from 'url';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Удаление позиции</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{title}}</p>
      <p>{{number}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-danger" (click)="confirmLotDelete(id)">Удалить</button>
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Отмена</button>
    </div>
  `
})
export class NgbdModalContent {
  @Input() id;
  @Input() title;
  @Input() number;

  constructor(public activeModal: NgbActiveModal, private managerService: ManagerService) { }

  confirmLotDelete(id: number) {

    //console.log('delete confirmed for lot:'+id);
    this.activeModal.close('Deleting confirmed');

  }
}

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss'],

})


export class ManagerComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private managerService: ManagerService,
    private modalService: NgbModal,
    private http: HttpClient, private changeDetector: ChangeDetectorRef) {

    // this.route.params.subscribe(data => {
    //   this.managerService.getLots().subscribe(res => {
    //     this.lots = res; 
    //     console.log('Лоты загружены ctor');
    //     //onsole.log(this.lots);
    //   }, error => {
    //     console.log('Ошибка при получении списка лотов!');
    //     // Ошибка при получении списка лотов!
    //   });
    // this.changeDetector.detectChanges();
    // })

  }
  selectedFiles: FileList = null;
  lots: Lot[] = [];
  ilot: Lot;
  region: string = '0';
  overallProgress: number = 0;
  userName: string = '';

  page = 1;
  pageSize: number = 10;

  managerRegions: { id: number, title: string }[] = [
    { 'id': 0, 'title': 'Техническая поддержка' },
    { 'id': 31, 'title': 'Белгородская область' },
    { 'id': 46, 'title': 'Курская область' },
    { 'id': 57, 'title': 'Орловская область' },
    { 'id': 25, 'title': 'Приморский край' }
  ];

  baseUrl: string = environment.apiUrl;

  ngOnInit() {
    let token = localStorage.getItem("jwt");
    let jwtData = token.split('.')[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    if (decodedJwtData.region == '0000')
      this.router.navigate(['manager/users']);
    this.userName = decodedJwtData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
    console.log(this.userName);
    //this.region = this.managerRegions.find(x => x.id == decodedJwtData.region).title;
    //console.log(this.managerRegions.find(x => x.id == decodedJwtData.region));
    //this.managerService.getLots().toPromise().then(res => {this.lots = res as Lot[]; console.log( this.lots);});
    this.managerService.getLotsByUser(this.userName).subscribe(res => {
      this.lots = res;
      console.log('Лоты загружены base');
      //onsole.log(this.lots);
    }, error => {
      console.log('Ошибка при получении списка лотов!');
      // Ошибка при получении списка лотов!
    });
    //this.managerService.getLots().subscribe(data => this.lots = data);
    //console.log( this.lots);
  }
  deleteLot(id: number, title: string, number: string) {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.number = number;
    modalRef.result.then(res => {
      this.managerService.deleteLot(id).subscribe(r => {
        console.log(r); // null - т.к. серверный метод ничего не возвращает
        this.ngOnInit(); // обновим список лотов
      }, error => console.log(error)); // По-хорошему в таком случае надо выдать уведомление об ошибке удаления лота!
    });
    console.log('delete lod:' + id);
    // this.managerService.deleteLot(id).subscribe(res => {
    //   console.log( res);
    //   //this.ngOnInit(); // обновим список лотов
    // }, error => console.log( error));

  }
  onFileSelected(event) {
    this.selectedFiles = <FileList>event.target.files;
  }
  async onUploadSubmit(event) {
    let headers: HttpHeaders = new HttpHeaders();
    let token = localStorage.getItem("jwt");
    headers = headers.append("Authorization", "Bearer " + token);

    if (this.selectedFiles) {
      // Вызвать Popup c анимацией?!
      let modalRefUniversal = this.modalService.open(UniversalModalComponent, { backdrop: 'static', keyboard: false, size: 'sm' });
      modalRefUniversal.componentInstance.isNotification = true;
      modalRefUniversal.componentInstance.isPreloaderEnabled = true;
      modalRefUniversal.componentInstance.footerEnabled = false;
      modalRefUniversal.componentInstance.message = 'Пожалуйста подождите';
      modalRefUniversal.componentInstance.title = 'Внимание';

      let files: FileList = this.selectedFiles;
      let errorFiles: string[] = [];
      let i = 0;
      while (i < files.length) {
        var isSuccess: boolean = false;
        if (files[i].name.toLowerCase().includes('jpg') || files[i].name.toLowerCase().includes('jpeg')) {
          var fullImage = await this.resizeImage(files[i], 'lg');
          var thumbImage = await this.resizeImage(fullImage, 'thumb');
          isSuccess = await this.uploadFile(fullImage, files[i].name, headers);
          isSuccess = await this.uploadFile(thumbImage, 'thumb_' + files[i].name, headers);
        } else // иначе грузим xls
          isSuccess = await this.uploadFile(files[i], files[i].name, headers);
        if (!isSuccess)
          errorFiles.push(files[i].name);
        i++;
        modalRefUniversal.componentInstance.message = 'Загружено файлов: ' + i + ' из ' + files.length;
      }
      if (errorFiles.length > 0) {
        modalRefUniversal.componentInstance.isNotification = false;
        modalRefUniversal.componentInstance.isWarning = true;
        modalRefUniversal.componentInstance.isPreloaderEnabled = false;
        modalRefUniversal.componentInstance.footerEnabled = true;
        modalRefUniversal.componentInstance.closeButtonEnabled = true;
        modalRefUniversal.componentInstance.message = errorFiles;
        modalRefUniversal.componentInstance.title = 'Загружены не все файлы';
      } else {
        modalRefUniversal.close();
        this.showCompleteModal('Файлы загружены');
      }

      this.managerService.getLotsByUser(this.userName).subscribe(res => {
        this.lots = res;
      }, error => {
        console.log('Ошибка при получении списка лотов!');
      });



      // let percent = 100 / files.length;
      // const filesList = from(files);
      // filesList.subscribe(file => {
      //   let fd = new FormData();
      //   fd.append('uploadedFiles', file, file.name);
      //   this.http.post(this.baseUrl + `/api/files`, fd, { headers: headers }).subscribe(res => {
      //     if (!res['isXlsOk']) {
      //       modalRefUniversal.close(); // закроем прелоадер
      //       let errorModalRef = this.modalService.open(ErrorModalComponent, { size: "lg", centered: true });
      //       errorModalRef.componentInstance.isXlsOk = res['isXlsOk'];
      //     } else {
      //       this.overallProgress += percent;
      //       if (this.overallProgress > 99) {
      //         modalRefUniversal.close(); // закроем прелоадер
      //         this.showCompleteModal('Файлы загружены');
      //         this.overallProgress = 0;
      //       }
      //       this.managerService.getLotsByUser(this.userName).subscribe(res => {
      //         this.lots = res;
      //       }, error => {
      //         console.log('Ошибка при получении списка лотов!');
      //       });
      //     }
      //   }, error => {
      //     modalRefUniversal.close(); // закроем прелоадер
      //     isOk = false;
      //     this.modalService.open(ErrorModalComponent, { size: "lg" })
      //   });
      // });


    }
  }

  uploadFile(file: Blob, name: string, headers: HttpHeaders): Promise<boolean> {
    return new Promise(resolve => {
      let fd = new FormData();
      fd.append('uploadedFiles', file, name);
      this.http.post(this.baseUrl + `/api/files`, fd, { headers: headers }).subscribe(res => {
        resolve(res['isXlsOk'])
      }
      );
    });
  }

  updateLot(id: number) {
    this.router.navigate(['update', id]);
  }
  createLot() {
    this.router.navigate(['add']);
  }
  async resizeImage(file: Blob, size: string): Promise<Blob> {
    let img = document.createElement("img");
    img.src = await new Promise<any>(resolve => {
      let reader = new FileReader();
      reader.onload = (e: any) => resolve(e.target.result);
      reader.readAsDataURL(file);
    });
    await new Promise(resolve => img.onload = resolve)
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    let MAX_WIDTH = size == 'lg' ? 1920 : 320;
    let MAX_HEIGHT = size == 'lg' ? 1080 : 240;
    let width = img.naturalWidth;
    let height = img.naturalHeight;
    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;
    ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    return await new Promise<Blob>(resolve => { canvas.toBlob(resolve, 'image/jpeg', 0.95); });
  }
  goToAuctions() {
    this.router.navigate(['events']);
  }
  public trackFunction(index, item: Lot) {
    if (!item) return null;
    return item
  }
  showLog(id: number) {
    const modalRef = this.modalService.open(LogModalComponent, { size: "lg" });
    modalRef.componentInstance.Id = id;
  }
  exit(event) {
    localStorage.removeItem("jwt");
    this.router.navigate(['']);
  }

  showCompleteModal(message: string) {
    let modalRefUniversal = this.modalService.open(UniversalModalComponent, { backdrop: 'static', keyboard: false, size: 'sm' });
    modalRefUniversal.componentInstance.isSuccess = true;
    modalRefUniversal.componentInstance.isPreloaderEnabled = false;
    modalRefUniversal.componentInstance.isPreloaderEnabled = false;
    modalRefUniversal.componentInstance.closeButtonEnabled = true;
    modalRefUniversal.componentInstance.footerEnabled = true;
    modalRefUniversal.componentInstance.message = message;
    modalRefUniversal.componentInstance.title = 'Выполнено';
  }

}
