import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManagerService } from '../../../../manager.service';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  @Input() IsActive;
  userName: string = '';
  password: string = '';
  passwordConfirm: string = '';
  regions;
  constructor(private route: ActivatedRoute,private router: Router, private managerService: ManagerService) { }

  ngOnInit() {
    this.userName = this.route.snapshot.params['userName'];
    this.managerService.getUserRegionsCodes(this.userName).subscribe(res=> {this.regions = res; console.log(this.regions);});
  }
  onSubmit() {
    this.managerService.updateUser(this.userName, this.password, this.regions)
    .subscribe(data => {this.router.navigate(['manager/users']);}, error => console.log(error));
  }
  CancelEdit() {
    this.router.navigate(['manager/users']);
  }

}
