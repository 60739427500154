import { Component, OnInit } from '@angular/core';
import ymaps from 'ymaps';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  map: any;
  constructor() { }

  ngOnInit() {
    document.getElementById("nav-footer").style.visibility = 'visible';
    ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru-RU')
      .then(maps => {
        console.log(maps);
        var myMap = new maps.Map('map', {
          center: [51.660781, 39.200269],
          zoom: 6
        });
        var myPlacemark = new maps.Placemark([51.871005, 37.739259], {
          balloonContentHeader: "Курский филиал",
          balloonContentBody: "306600, Курская область, Советский р-н, д. Дицево, 1",
          balloonContentFooter: "",
          hintContent: "Курский филиал"
        });
        var myPlacemark2 = new maps.Placemark([50.607057, 36.579162], {
          balloonContentHeader: "Белгородский филиал",
          balloonContentBody: "3008002, Белгородская область, г. Белгород, пр-т Б. Хмельницкого, д. 111",
          balloonContentFooter: "",
          hintContent: "Белгородский филиал"
        });
        var myPlacemark3 = new maps.Placemark([53.325900, 36.693056], {
          balloonContentHeader: "Орловский филиал",
          balloonContentBody: "303003, Орловская область, Мценский р-н, д. Большая Каменка, ул. Молодежная, д. 1",
          balloonContentFooter: "",
          hintContent: "Орловский филиал"
        });
        var myPlacemark4 = new maps.Placemark([50.159876, 38.627443], {
          balloonContentHeader: "Офис в Вейделевском районе",
          balloonContentBody: "309731, Белгородская область, Вейделевский р-н, с. Закутское, ул. Центральная, д. 7",
          balloonContentFooter: "",
          hintContent: "Офис в Вейделевском районе"
        });
        var myPlacemark5 = new maps.Placemark([43.789938, 131.947798], {
          balloonContentHeader: "Приморский филиал",
          balloonContentBody: "692519, Приморский край, г. Уссурийск, ул. Тимирязева, д. 29",
          balloonContentFooter: "",
          hintContent: "Приморский филиал"
        });
        var myPlacemark6 = new maps.Placemark([52.719373, 41.456468], {
          balloonContentHeader: "Тамбовский филиал",
          balloonContentBody: "392000, Тамбовская область, г. Тамбов, ул. М. Горького, д. 16",
          balloonContentFooter: "",
          hintContent: "Тамбовский филиал"
        });
        myMap.geoObjects.add(myPlacemark);
        myMap.geoObjects.add(myPlacemark2);
        myMap.geoObjects.add(myPlacemark3);
        myMap.geoObjects.add(myPlacemark4);
        myMap.geoObjects.add(myPlacemark5);
        myMap.geoObjects.add(myPlacemark6);

      })
      .catch(error => console.log('Failed to load Yandex Maps', error));

  }

}
