import { Component, OnInit , Input} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  @Input() Message: string;
  @Input() isXlsOk: boolean = true;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
