import { Component, OnInit } from '@angular/core';
import { ManagerService } from '../manager.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UniversalModalComponent } from '../universal-modal/universal-modal.component';

import { Auction } from '../Auction';

@Component({
  selector: 'app-bidding-manager',
  templateUrl: './bidding-manager.component.html',
  styleUrls: ['./bidding-manager.component.scss']
})
export class BiddingManagerComponent implements OnInit {

  region: string = '';
  auctions: Auction[];
  auctionFile: File = null;
  page = 1;
  pageSize: number = 6;

  constructor(private router: Router, private managerService: ManagerService, private modalService: NgbModal) {

  }
  ngOnInit() {
    this.auctions = new Array();
    this.loadAuctions();
  }

  loadAuctions() {
    let token = localStorage.getItem("jwt");
    let jwtData = token.split('.')[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    //this.region = this.managerRegions.find(x => x.id == decodedJwtData.region).title;
    console.log(decodedJwtData.region);
    // Получить список аукционов для текущего региона
    this.managerService.getEventsByRegionCodes(decodedJwtData.region).subscribe(res => {
      this.auctions = res as Auction[];
      console.log(this.auctions);
    }, error => {
      console.log('Ошибка при получении списка аукционов!' + error);
    });
  }

  onFileSelected(event) {
    this.auctionFile = <File>event.target.files[0];
    //console.log(<File>event.target.files[0]);
  }
  onUploadSubmit(event) {
    let headers: HttpHeaders = new HttpHeaders();
    let token = localStorage.getItem("jwt");
    headers = headers.append("Authorization", "Bearer " + token);

    if (this.auctionFile) {
      let percent = 100;

      let modalRefUniversal = this.modalService.open(UniversalModalComponent, { backdrop: 'static', keyboard: false, size: 'sm' });
      modalRefUniversal.componentInstance.isNotification = true;
      modalRefUniversal.componentInstance.isPreloaderEnabled = true;
      modalRefUniversal.componentInstance.footerEnabled = false;
      modalRefUniversal.componentInstance.message = 'Файлы загружаются, пожалуйста подождите';
      modalRefUniversal.componentInstance.title = 'Внимание';

      let fd = new FormData();
      fd.append('uploadedFile', this.auctionFile);
      console.log(this.auctionFile.name);
      this.managerService.uploadEventFile(fd).subscribe(res => {
        modalRefUniversal.close();
        modalRefUniversal = this.modalService.open(UniversalModalComponent, { backdrop: 'static', keyboard: false, size: 'sm' });
        modalRefUniversal.componentInstance.isSuccess = true;
        modalRefUniversal.componentInstance.closeButtonEnabled = true;
        modalRefUniversal.componentInstance.footerEnabled = true;
        modalRefUniversal.componentInstance.message = 'Список аукционов импортирован';
        modalRefUniversal.componentInstance.title = 'Выполнено';
        this.loadAuctions();
      }, error => {
        modalRefUniversal.close();
        modalRefUniversal = this.modalService.open(UniversalModalComponent, { backdrop: 'static', keyboard: false, size: 'sm' });
        modalRefUniversal.componentInstance.isDanger = true;
        modalRefUniversal.componentInstance.closeButtonEnabled = true;
        modalRefUniversal.componentInstance.footerEnabled = true;
        modalRefUniversal.componentInstance.message = 'Не удалось импортировать список аукционов';
        modalRefUniversal.componentInstance.title = 'Ошибка';
      });

    }
  }
  goToManager() {
    this.router.navigate(['manager']);
  }

}
