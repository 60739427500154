import { Component, OnInit } from '@angular/core';
import { ManagerService } from '../../../manager.service';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss']
})
export class UsermanagementComponent implements OnInit {

  users;
  isSupportUser = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private managerService: ManagerService, ) { }

  ngOnInit() {
    let token = localStorage.getItem("jwt");
    let jwtData = token.split('.')[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    console.log(decodedJwtData);
    if(decodedJwtData.region == '0000') {
      this.isSupportUser = true;
      this.managerService.getUsers().subscribe(result => {this.users = result; console.log(result)}, err => console.log(err));
    }
  }
  editUser(userName: string) {
    this.router.navigate(['manager/users/edit', userName]);
  }
  createUser() {
    this.router.navigate(['manager/users/create']);
  }
  disableUser(userName: string) {
    this.managerService.disableUser(userName).subscribe(result => {
      this.managerService.getUsers().subscribe(result => {
        this.users = result;
        console.log(result)
      }, err => console.log(err));
      console.log(result)}, err => console.log(err));
  }
  enableUser(userName: string) {
    this.managerService.enableUser(userName).subscribe(result => {
      this.managerService.getUsers().subscribe(result => {
        this.users = result;
        console.log(result)
      }, err => console.log(err));
      console.log(result)}, err => console.log(err));
  }
  exit(event) {
    localStorage.removeItem("jwt");
    this.router.navigate(['']);
  }

}
