import { Component, OnInit } from '@angular/core';
import { ManagerService } from '../manager.service';
import { FormsModule, FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.scss']
})
export class PartsComponent implements OnInit {
  page = 1;
  pageSize: number = 25;
  parts: any[] = [];
  searchGroup: FormGroup;
  titleSearchString: string = "";
  fitsSearchString: string = "";
  regionSearchString: string = "";
  constructor(private managerService: ManagerService) { }

  ngOnInit() {
    this.searchGroup = new FormGroup({
      cSearchTitle: new FormControl(),
      cSearchFits: new FormControl(),
      cSearchRegion: new FormControl()
    });
    this.managerService.getParts().subscribe(res => {
      this.parts = res;
      console.log(this.parts)
    }, err => console.log(err));
  }
  searchByTitle(event: any) {
    this.titleSearchString = event.target.value;
    if (this.titleSearchString.length > 0)
      this.managerService.getPartsByFilter(this.titleSearchString, this.fitsSearchString, this.regionSearchString).subscribe(res => this.parts = res)
  }
  searchByFits(event: any) {
    this.fitsSearchString = event.target.value;
    if (this.fitsSearchString.length > 0)
      this.managerService.getPartsByFilter(this.titleSearchString, this.fitsSearchString, this.regionSearchString).subscribe(res => this.parts = res)
  }
  searchByRegion(event: any) {
    this.regionSearchString = event.target.value;
    if (this.regionSearchString.length > 0)
      this.managerService.getPartsByFilter(this.titleSearchString, this.fitsSearchString, this.regionSearchString).subscribe(res => this.parts = res)
  }
  clearTitle() {
    this.searchGroup.controls['cSearchTitle'].setValue("");
    this.titleSearchString = "";
    if (this.titleSearchString.length == 0 && this.fitsSearchString.length == 0 && this.regionSearchString.length == 0)
      this.managerService.getParts().subscribe(res => {
        this.parts = res;
        console.log(this.parts)
      }, err => console.log(err));
  }
  clearFits() {
    this.searchGroup.controls['cSearchFits'].setValue("");
    this.fitsSearchString = "";
    if (this.titleSearchString.length == 0 && this.fitsSearchString.length == 0 && this.regionSearchString.length == 0)
    this.managerService.getParts().subscribe(res => {
      this.parts = res;
      console.log(this.parts)
    }, err => console.log(err));
  }
  clearRegion() {
    this.searchGroup.controls['cSearchRegion'].setValue("");
    this.regionSearchString = "";
    if (this.titleSearchString.length == 0 && this.fitsSearchString.length == 0 && this.regionSearchString.length == 0)
    this.managerService.getParts().subscribe(res => {
      this.parts = res;
      console.log(this.parts)
    }, err => console.log(err));
  }

}
