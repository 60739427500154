import { Component, Input  } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { Lot } from '../Lot';
@Component({
  selector: 'app-lot-details-modal',
  templateUrl: './lot-details-modal.component.html',
  styleUrls: ['./lot-details-modal.component.scss']
})

export class LotDetailsModalComponent {
  @Input() lot: Lot;
  @Input() childLots: Lot[];
  @Input() hasChildLots: boolean = false;
  @Input() gallery: any[];
  closeResult: string;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor(public activeModal: NgbActiveModal) {
    this.galleryOptions = [{'width': '600px','height': '500px', 'previewKeyboardNavigation': true, 'thumbnailsRows': 1, 'previewRotate': true, 'previewInfinityMove': true, 'imageArrows': false, 'previewZoom': true}];
  }
}
